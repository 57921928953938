/* Set the size of the div element that contains the map */
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.nav-bar {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px;
  /* Vertically center the text there */
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
}


/* Typography
-------------------------------------------------- */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: SharpSansRegular, Arial, sans-serif;
  font-weight: bold;
  color: #444444;
  letter-spacing: 0;
  margin: 0;
  text-transform: initial;
}

a :hover {
  color: #444444;
}

h1,
.h1 {
  font-size: 3em;
  font-weight: 100;
  line-height: 1.15;
}

h2,
.h2 {
  font-size: 2.25em;
  line-height: 1.25em;
  /**
  * Only h2 will remain unbold, 
  * except transcation-dashboard places
  */
  font-weight: 100;
}

h3,
.h3 {
  font-size: 1.75em;
  line-height: 1.4em;
}

h4,
.h4 {
  font-size: 1.5em;
  margin-bottom: 5px;
}

h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: .03em;
}

/*
  buttons
*/
.btn {
  font-size: 1rem;
  border: 2px solid transparent;
  text-decoration: none;
  outline: 0;
  font-family: SharpSansRegular, Arial, sans-serif;
  line-height: 1.375em;
  min-width: 110px;  
  box-shadow: 1px 1px 8px -3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  white-space: nowrap;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 30px !important;
}

.back-btn {
  background-color: #DBDCDE !important;
  color: #444444 !important;
}

.save-btn {
  background-color: #32cd32 !important;
  color: #fff !important;
}

.save-btn-red {
  background-color: #ff0000 !important;
  color: #fff !important;
}

label {
  font-family: SharpSansRegular, Arial, sans-serif;
}

/*
  table
*/
tr,
td {
  border-bottom: 1px solid #DBDCDE;
}

th {
  font-weight: bold;
  border-left: 0;
  border-right: 0;
  border-bottom: none;
  color: #444444;

  padding: 8px 12px;
  border-width: 0 0 1px 1px;
  white-space: nowrap;
}

.jsgrid-cell,
.jsgrid-header-cell {
  border-left: 0 !important;
  border-right: 0 !important;
  vertical-align: top;
  color: #444444;
}

/*
  readonly
*/
select[readonly] {
  background: #eee;
  pointer-events: none;
  touch-action: none;
}

.required::after {
  content: "*";
  color: red;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}